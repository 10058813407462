<template>
  <div v-if="vehicle" id="service-eyesafe">
    <!-- IGNITION CONTROL -->
    <div v-if="has_eyesafe_ignition" id="service-eyesafe.ignitioncontrol">
      <div class="mb-12">
        <div class="flex flex-row justify-between items-baseline mb-2">
          <p class="text-xl font-bold">Ignition Control</p>
          <button
            class="text-xs bg-theme-21 text-white px-2 py-1 rounded-md"
            @click="openAuthorizedDriversModal"
          >
            Autisti autorizzati
          </button>
        </div>
        <div v-if="ignitionStatus" class="border border-gray-500 p-5">
          <div class="flex justify-between items-baseline mb-4">
            <div class="text-lg capitalize">Ultimo stato</div>
            <DateAgo
              v-if="ignitionStatus.datetime"
              :datetime="ignitionStatus.datetime"
            ></DateAgo>
            <div v-else>
              {{ i18n.t("services.no_data") }}
            </div>
          </div>
          <div class="flex flex-row justify-around">
            <VehicleParameter
              name="Avviamento Motore"
              :icon="ignitionStatus.status ? 'LogInIcon' : 'LockIcon'"
              :value="ignitionStatus.status"
              :format="formatIgnitionStatus"
              :setclass="formatIgnitionStatusClass"
            />
            <VehicleParameter
              name="Quadro Elettrico"
              :icon="ignitionStatus.status ? 'LogInIcon' : 'LockIcon'"
              :value="ignitionStatus.ignition === 1 ? 'Acceso' : 'Spento'"
              :setclass="
                ignitionStatus.ignition === 1 ? '!bg-green-500' : '!bg-gray-500'
              "
            />
          </div>

          <div class="mt-4">
            <p class="text-center" v-if="ignitionStatus.ignition == 1">
              Quadro elettrico acceso: Invio comandi disabilitato
            </p>
            <button
              :disabled="ignitionStatus.ignition === 1"
              class="btn-reyesol-report"
              @click="sendStartUnlockCommand()"
            >
              Abilita avviamento
            </button>
            <div class="flex flex-row">
              <button
                :disabled="
                  ignitionStatus.status === 2 || ignitionStatus.ignition === 1
                "
                class="btn-reyesol-report"
                @click="sendStartLockCommand()"
              >
                Disabilita avviamento locale
              </button>
              <button
                :disabled="
                  ignitionStatus.status !== 2 || ignitionStatus.ignition === 1
                "
                class="btn-reyesol-report"
                @click="sendResetLockCommand()"
              >
                Ripristino avviamento locale
              </button>
            </div>
          </div>
        </div>
      </div>
      <div v-if="ignitionData">
        <div class="flex justify-between items-baseline mb-4">
          <div class="text-lg capitalize">
            {{ i18n.t("services.eyesafe.last_status") }}
          </div>
          <DateAgo
            v-if="ignitionData.command"
            :datetime="ignitionData.command.message_datetime"
          ></DateAgo>
          <div v-else>
            {{ i18n.t("services.no_data") }}
          </div>
        </div>

        <div v-if="ignitionData.command" class="report-box mb-8">
          <div class="box px-4 py-4 mb-3 flex items-center justify-around">
            <VehicleParameter
              :active="ignitionData.command.status !== null"
              :name="i18n.t('services.eyesafe.status.name')"
              icon="TrendingDownIcon"
              :value="ignitionData.command.status"
              :setclass="
                () =>
                  ignitionData.command.status == 5 ||
                  ignitionData.command.status == 4 ||
                  ignitionData.command.status == 1
                    ? 'bg-reyesol-alert-warning'
                    : ignitionData.command.status == 7 ||
                      ignitionData.command.status == 6 ||
                      ignitionData.command.status == 3
                    ? 'bg-reyesol-alert-alarm'
                    : 'bg-reyesol-gray'
              "
              :format="(val) => i18n.t('services.eyesafe.status.' + val)"
            />
            <VehicleParameter
              :active="ignitionData.command.user !== null"
              :name="i18n.t('services.eyesafe.user.name')"
              icon="UserIcon"
              :value="ignitionData.command.user"
            />
            <VehicleParameter
              :active="ignitionData.command.action !== null"
              :name="i18n.t('services.eyesafe.action.name')"
              icon="SlidersIcon"
              :value="
                i18n.t('services.eyesafe.action.' + ignitionData.command.action)
              "
            />
          </div>
        </div>
        <div v-else class="flex justify-center py-6">
          <loading-icon icon="rings" class="w-20 h-20" />
        </div>
        <div class="py-6">
          <div class="text-lg capitalize mb-4">
            {{ i18n.t("services.eyesafe.command_history") }}
            <Eyepicker v-model="ignitionCommandsDaterange" />
          </div>
          <div
            v-if="!loadingIgnitionHistory"
            class="max-h-80 overflow-y-auto text-center"
          >
            <div
              v-if="
                ignitionHistory &&
                ignitionHistory.list &&
                ignitionHistory.list.length > 0
              "
            >
              <div v-for="h in ignitionHistory.list.command" :key="h.id">
                <div class="intro-y">
                  <div class="box px-4 py-4 mb-3 flex items-center">
                    <div class="flex grow">
                      {{ dayjs(h.message_datetime).format("DD/MM/YYYY HH:mm") }}
                    </div>
                    <VehicleParameter
                      :active="h.status !== null"
                      :name="i18n.t('services.eyesafe.status.name')"
                      icon="TrendingDownIcon"
                      :value="h.status"
                      :setclass="
                        () =>
                          h.status == 5 || h.status == 4 || h.status == 1
                            ? 'bg-reyesol-alert-warning'
                            : h.status == 7 || h.status == 6 || h.status == 3
                            ? 'bg-reyesol-alert-alarm'
                            : 'bg-reyesol-gray'
                      "
                      :format="
                        (val) => i18n.t('services.eyesafe.status.' + val)
                      "
                    />
                    <VehicleParameter
                      :active="h.user !== null"
                      :name="i18n.t('services.eyesafe.user.name')"
                      icon="UserIcon"
                      :value="h.user"
                    />
                    <VehicleParameter
                      :active="h.action !== null"
                      :name="i18n.t('services.eyesafe.action.name')"
                      icon="SlidersIcon"
                      :value="i18n.t('services.eyesafe.action.' + h.action)"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div v-else class="font-light text-xl text-gray-500">
              {{ i18n.t("general.no-results") }}
            </div>
          </div>
          <loading-icon v-else icon="rings" class="w-20 h-20" />
          <div
            class="btn-reyesol-report"
            @click="requestIgnitionCommandsReport"
          >
            <FileTextIcon /> {{ i18n.t("general.request-report") }}
          </div>
        </div>

        <!-- IGNITION EVENT HISTORY -->
        <div class="py-6">
          <div class="text-lg capitalize mb-4">
            Cronologia Eventi
            <Eyepicker v-model="ignitionEventsDaterange" />
          </div>
          <div
            v-if="!loadingIgnitionEvents"
            class="max-h-80 overflow-y-auto text-center"
          >
            <div v-if="ignitionEvents && ignitionEvents.events">
              <div v-for="event in ignitionEvents.events" :key="event.id">
                <div class="intro-y">
                  <div class="box px-4 py-4 mb-3 flex items-center">
                    <div class="flex grow">
                      {{ dayjs(event.datetime).format("DD/MM/YYYY HH:mm") }}
                    </div>
                    <VehicleParameter
                      :active="event.user !== null"
                      name="Attività"
                      :value="event.message"
                    />
                    <VehicleParameter
                      :active="event.user !== null"
                      :name="event.type === 'REMOTE' ? 'Utente' : 'Autista'"
                      :value="event.user_name ? event.user_name : 'Sconosciuto'"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div v-else class="font-light text-xl text-gray-500">
              {{ i18n.t("general.no-results") }}
            </div>
          </div>
          <loading-icon v-else icon="rings" class="w-20 h-20" />
          <div class="btn-reyesol-report" @click="requestIgnitionEventsReport">
            <FileTextIcon /> {{ i18n.t("general.request-report") }}
          </div>
        </div>

        <!-- IGNITION DRIVER TRIP HISTORY -->
        <div class="py-6">
          <div class="text-lg capitalize mb-4">
            Guide Autisti
            <Eyepicker v-model="ignitionTripsDaterange" />
          </div>
          <div class="btn-reyesol-report" @click="requestIgnitionTripsReport">
            <FileTextIcon /> {{ i18n.t("general.request-report") }}
          </div>
        </div>
      </div>
    </div>

    <!-- LOCK CONTROL -->
    <div v-if="has_eyesafe_lock" id="service-eyesafe.doorcontrol" class="mt-8">
      <div class="mb-12">
        <p class="text-xl font-bold">Lock Control</p>
        <div v-if="lockStatus" class="border border-gray-500 p-5">
          <div class="flex justify-between items-baseline mb-4">
            <div class="text-lg capitalize">Ultimo stato</div>
            <DateAgo
              v-if="lockStatus.datetime"
              :datetime="lockStatus.datetime"
            ></DateAgo>
            <div v-else>
              {{ i18n.t("services.no_data") }}
            </div>
          </div>
          <div class="flex flex-row justify-around">
            <!-- LOCK STATUS -->
            <VehicleParameter
              name="Serratura"
              :icon="lockStatus.status ? 'LogInIcon' : 'LockIcon'"
              :value="lockStatus.status"
              :format="formatLockStatus"
              :setclass="
                (value) =>
                  value == 0 ? 'bg-reyesol-current-in-range' : '!bg-green-500'
              "
            />

            <!-- DOORS STATUS -->
            <div class="flex flex-row">
              <VehicleParameter
                name="Porta 1"
                :icon="lockStatus.door1_status ? 'LogInIcon' : 'LockIcon'"
                :value="lockStatus.door1_status"
                :format="formatDoorStatus"
                :setclass="
                  (value) => {
                    if (value == null)
                      return 'bg-reyesol-current-warning-range';
                    if (value === 0) return 'bg-reyesol-current-in-range';
                    if (value === 1) return 'bg-reyesol-current-alarm-range';
                    return value;
                  }
                "
              />
              <VehicleParameter
                name="Porta 2"
                :icon="lockStatus.door1_status ? 'LogInIcon' : 'LockIcon'"
                :value="lockStatus.door2_status"
                :format="formatDoorStatus"
                :setclass="
                  (value) => {
                    if (value == null)
                      return 'bg-reyesol-current-warning-range';
                    if (value === 0) return 'bg-reyesol-current-in-range';
                    if (value === 1) return 'bg-reyesol-current-alarm-range';
                    return value;
                  }
                "
              />
            </div>
          </div>
        </div>
        <div class="btn-reyesol-report" @click="sendDoorUnlockCommand()">
          Sblocca
        </div>
      </div>
      <div v-if="doorData">
        <div class="flex justify-between items-baseline mb-4">
          <div class="text-lg capitalize">
            {{ i18n.t("services.eyesafe.last_status") }}
          </div>
          <DateAgo
            v-if="doorData.command"
            :datetime="doorData.command.message_datetime"
          ></DateAgo>
          <div v-else>
            {{ i18n.t("services.no_data") }}
          </div>
        </div>

        <div v-if="doorData.command" class="report-box mb-8">
          <div class="box px-4 py-4 mb-3 flex items-center justify-around">
            <VehicleParameter
              :active="doorData.command.status !== null"
              :name="i18n.t('services.eyesafe.status.name')"
              icon="TrendingDownIcon"
              :value="doorData.command.status"
              :setclass="
                () =>
                  doorData.command.status == 5 ||
                  doorData.command.status == 4 ||
                  doorData.command.status == 1
                    ? 'bg-reyesol-alert-warning'
                    : doorData.command.status == 7 ||
                      doorData.command.status == 6 ||
                      doorData.command.status == 3
                    ? 'bg-reyesol-alert-alarm'
                    : 'bg-reyesol-gray'
              "
              :format="(val) => i18n.t('services.eyesafe.status.' + val)"
            />
            <VehicleParameter
              :active="doorData.command.user !== null"
              :name="i18n.t('services.eyesafe.user.name')"
              icon="UserIcon"
              :value="doorData.command.user"
            />
            <VehicleParameter
              :active="doorData.command.action !== null"
              :name="i18n.t('services.eyesafe.action.name')"
              icon="SlidersIcon"
              :value="
                i18n.t('services.eyesafe.action.' + doorData.command.action)
              "
            />
          </div>
        </div>
        <div v-else class="flex justify-center py-6">
          <loading-icon icon="rings" class="w-20 h-20" />
        </div>
        <div class="py-6">
          <div class="text-lg capitalize mb-4">
            {{ i18n.t("services.eyesafe.command_history") }}
            <Eyepicker v-model="doorDaterange" />
          </div>
          <div
            v-if="!loadingLockHistory"
            class="max-h-80 overflow-y-auto text-center"
          >
            <div
              v-if="
                doorHistory && doorHistory.list && doorHistory.list.length > 0
              "
            >
              <div v-for="h in doorHistory.list.command" :key="h.id">
                <div class="intro-y">
                  <div class="box px-4 py-4 mb-3 flex items-center">
                    <div class="flex grow">
                      {{ dayjs(h.message_datetime).format("DD/MM/YYYY HH:mm") }}
                    </div>
                    <VehicleParameter
                      :active="h.status !== null"
                      :name="i18n.t('services.eyesafe.status.name')"
                      icon="TrendingDownIcon"
                      :value="h.status"
                      :setclass="
                        () =>
                          h.status == 5 || h.status == 4 || h.status == 1
                            ? 'bg-reyesol-alert-warning'
                            : h.status == 7 || h.status == 6 || h.status == 3
                            ? 'bg-reyesol-alert-alarm'
                            : 'bg-reyesol-gray'
                      "
                      :format="
                        (val) => i18n.t('services.eyesafe.status.' + val)
                      "
                    />
                    <VehicleParameter
                      :active="h.user !== null"
                      :name="i18n.t('services.eyesafe.user.name')"
                      icon="UserIcon"
                      :value="h.user"
                    />
                    <VehicleParameter
                      :active="h.action !== null"
                      :name="i18n.t('services.eyesafe.action.name')"
                      icon="SlidersIcon"
                      :value="i18n.t('services.eyesafe.action.' + h.action)"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div v-else class="font-light text-xl text-gray-500">
              {{ i18n.t("general.no-results") }}
            </div>
          </div>
          <loading-icon v-else icon="rings" class="w-20 h-20" />
          <div class="btn-reyesol-report" @click="requestLockCommandsReport">
            <FileTextIcon /> {{ i18n.t("general.request-report") }}
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Authorized Drivers Modal -->
  <Teleport to="body">
    <div
      v-if="showAuthorizedDriversModal"
      class="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center"
    >
      <div
        class="bg-white p-6 rounded-lg max-w-2xl w-full max-h-[80vh] overflow-y-auto"
      >
        <div class="flex justify-between items-center mb-4">
          <h2 class="text-xl font-bold">Autisti Autorizzati</h2>
          <button
            @click="showAuthorizedDriversModal = false"
            class="text-gray-500 hover:text-gray-700"
          >
            <span class="sr-only">Close</span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
        </div>

        <div v-if="loadingAuthorizedDrivers" class="flex justify-center py-6">
          <loading-icon icon="rings" class="w-20 h-20" />
        </div>
        <div
          v-else-if="ignitionDataAuthorizedDrivers.length === 0"
          class="text-center py-6 text-gray-500"
        >
          Nessun autista autorizzato trovato.
        </div>
        <div v-else class="divide-y">
          <div
            v-for="driver in ignitionDataAuthorizedDrivers"
            :key="driver.id"
            class="py-3"
          >
            <div class="flex items-center">
              <div class="flex-1">
                <div class="font-medium">
                  {{ driver.name }} {{ driver.surname }}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="mt-6 flex justify-end">
          <button
            @click="showAuthorizedDriversModal = false"
            class="px-4 py-2 bg-gray-200 text-gray-800 rounded hover:bg-gray-300"
          >
            Chiudi
          </button>
        </div>
      </div>
    </div>
  </Teleport>
</template>

<script>
/* eslint-disable */
import { watch, ref, computed, reactive, onUnmounted } from "vue";
import { useI18n } from "vue3-i18n";
import { useStore } from "vuex";
import { useDaterange } from "@/composables/daterange";
import dayjs from "dayjs";
import EventBus from "@/libs/event-bus";
import VehicleParameter from "@/components/vehicle/VehicleParameter.vue";

export default {
  components: { VehicleParameter },
  props: {
    active: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const store = useStore();
    const i18n = useI18n();
    const vehicle = computed(() => store.getters["globalMap/selectedVehicle"]);
    const ignitionStatus = ref(null);
    const ignitionData = ref(null);
    const ignitionHistory = ref(null);
    const ignitionEvents = ref(null);
    const lockStatus = ref(null);
    const doorData = ref(null);
    const doorHistory = ref(null);
    const loadingIgnitionHistory = ref(false);
    const loadingIgnitionEvents = ref(false);
    const loadingLockHistory = ref(false);
    const {
      daterange: ignitionCommandsDaterange,
      from: ignitionHistoryFrom,
      to: ignitionHistoryTo,
    } = useDaterange({
      onChange: getIgnitionHistory,
    });
    const {
      daterange: ignitionEventsDaterange,
      from: ignitionEventsFrom,
      to: ignitionEventsTo,
    } = useDaterange({
      onChange: getIgnitionEvents,
    });
    const {
      daterange: ignitionTripsDaterange,
      from: ignitionTripsFrom,
      to: ignitionTripsTo,
    } = useDaterange({
      onChange: getIgnitionTrips,
    });
    const {
      daterange: doorDaterange,
      from: doorHistoryFrom,
      to: doorHistoryTo,
    } = useDaterange({
      onChange: getDoorHistory,
    });

    const ignitionDataAuthorizedDrivers = reactive(ref([]));
    const loadingAuthorizedDrivers = ref(false);
    const showAuthorizedDriversModal = ref(false);

    const has_eyesafe_ignition = ref(false);
    const has_eyesafe_lock = ref(false);

    async function getAuthorizedDrivers() {
      if (!vehicle.value || !vehicle.value.id) return;

      loadingAuthorizedDrivers.value = true;
      try {
        const result = await store.dispatch(
          "eyesafe/getAuthorizedDrivers",
          vehicle.value.id,
        );
        if (result.validated) {
          ignitionDataAuthorizedDrivers.value = result.validated;
        }
      } catch (error) {
        console.error("Error loading authorized drivers:", error);
        EventBus.emit("notification:show", {
          type: "error",
          title: i18n.t("services.eyesafe.error_loading_drivers"),
        });
      } finally {
        loadingAuthorizedDrivers.value = false;
      }
    }

    function openAuthorizedDriversModal() {
      showAuthorizedDriversModal.value = true;
    }

    // poll interval variable
    let pollInterval = null;

    // Computed property for formatted ignition status
    function formatIgnitionStatus(status) {
      if (status === 0) return "Disabilitato";
      if (status === 1) return "Abilitato";
      if (status === 2) return "Avviamento locale disabilitato";
      return "Sconosciuto";
    }

    // Computed property for formatted ignition status
    function formatIgnitionStatusClass(status) {
      if (status === 0) return "bg-reyesol-current-in-range";
      if (status === 1) return "!bg-green-500";
      if (status === 2) return "!bg-yellow-500";
      return "bg-reyesol-gray";
    }

    // Computed property for formatted lock status
    function formatLockStatus(status) {
      if (status === 0) return "Bloccata";
      if (status === 1) return "Sbloccata";
      return status;
    }

    // Computed property for formatted lock status
    function formatDoorStatus(status) {
      if (status == null) return "Sconosciuto";
      if (status === 0) return "Chiusa";
      if (status === 1) return "Aperta";
      return status;
    }

    // watch service visibility
    watch(
      () => props.active,
      () => {
        if (props.active) {
          initService();
          startPolling();
        } else {
          stopPolling();
        }
      },
    );

    onUnmounted(() => {
      stopPolling();
    });

    async function initService() {
      has_eyesafe_ignition.value = !!vehicle.value.active_agreements.find(
        (o) => o.agreement_id == 31,
      );

      has_eyesafe_lock.value = !!vehicle.value.active_agreements.find(
        (o) => o.agreement_id == 32,
      );

      if (has_eyesafe_ignition.value) {
        const ignitionStatusResult = await store.dispatch(
          "eyesafe/getIgnitionStatus",
          vehicle.value.id,
        );
        ignitionStatus.value = ignitionStatusResult.validated;

        const ignitionDataResult = await store.dispatch(
          "eyesafe/getIgnitionLastCommand",
          vehicle.value.id,
        );
        ignitionData.value = ignitionDataResult.validated;

        getIgnitionHistory();
        getIgnitionEvents();
        getAuthorizedDrivers();
      }

      if (has_eyesafe_lock.value) {
        const lockStatusResult = await store.dispatch(
          "eyesafe/getLockStatus",
          vehicle.value.id,
        );
        lockStatus.value = lockStatusResult.validated;

        const doorDataResult = await store.dispatch(
          "eyesafe/getLockLastCommand",
          vehicle.value.id,
        );
        doorData.value = doorDataResult.validated;

        getDoorHistory();
      }
    }

    async function pollCommands() {
      if (!vehicle.value || !vehicle.value.id) return;
      const ignitionStatusResult = await store.dispatch(
        "eyesafe/getIgnitionStatus",
        vehicle.value.id,
      );
      ignitionStatus.value = ignitionStatusResult.validated;

      const ignitionDataResult = await store.dispatch(
        "eyesafe/getIgnitionLastCommand",
        vehicle.value.id,
      );
      ignitionData.value = ignitionDataResult.validated;

      if (!vehicle.value || !vehicle.value.id) return;
      const lockStatusResult = await store.dispatch(
        "eyesafe/getLockStatus",
        vehicle.value.id,
      );
      lockStatus.value = lockStatusResult.validated;

      const doorDataResult = await store.dispatch(
        "eyesafe/getLockLastCommand",
        vehicle.value.id,
      );
      doorData.value = doorDataResult.validated;
    }

    function startPolling() {
      // Initial poll then every 10 seconds
      pollCommands();
      pollInterval = setInterval(pollCommands, 10000);
    }

    function stopPolling() {
      if (pollInterval) {
        clearInterval(pollInterval);
        pollInterval = null;
      }
    }

    async function requestIgnitionCommandsReport() {
      emit(
        "report-request",
        ["eyesafe"],
        "master",
        [].concat(
          store.getters["globalMap/selectedVehicleSensors"].eyesafe_ignition,
        ),
      );
    }

    async function requestIgnitionEventsReport() {
      emit(
        "report-request",
        ["eyesafe"],
        "ignition_events",
        [].concat(
          store.getters["globalMap/selectedVehicleSensors"].eyesafe_ignition,
        ),
      );
    }

    async function requestIgnitionTripsReport() {
      emit(
        "report-request",
        ["eyesafe"],
        "ignition_trips",
        [].concat(
          store.getters["globalMap/selectedVehicleSensors"].eyesafe_ignition,
        ),
      );
    }

    async function requestLockCommandsReport() {
      emit(
        "report-request",
        ["eyesafe"],
        "master",
        [].concat(
          store.getters["globalMap/selectedVehicleSensors"].eyesafe_lock,
        ),
      );
    }

    async function getIgnitionHistory() {
      if (!props.active) return;
      loadingIgnitionHistory.value = true;

      const ignitionHistoryResult = await store.dispatch(
        "eyesafe/getIgnitionCommandHistory",
        {
          vehicleId: vehicle.value.id,
          dateFrom: dayjs(ignitionHistoryFrom.value).format(),
          dateTo: dayjs().isSame(dayjs(ignitionHistoryTo.value), "day")
            ? dayjs().format()
            : dayjs(ignitionHistoryTo.value).format(),
        },
      );
      ignitionHistory.value = ignitionHistoryResult.validated;

      loadingIgnitionHistory.value = false;
    }

    async function getIgnitionEvents() {
      if (!props.active) return;
      loadingIgnitionEvents.value = true;

      const ignitionEventsResult = await store.dispatch(
        "eyesafe/getIgnitionEventHistory",
        {
          vehicleId: vehicle.value.id,
          dateFrom: dayjs(ignitionEventsFrom.value).format(),
          dateTo: dayjs().isSame(dayjs(ignitionEventsTo.value), "day")
            ? dayjs().format()
            : dayjs(ignitionEventsTo.value).format(),
        },
      );
      ignitionEvents.value = ignitionEventsResult.validated;

      loadingIgnitionEvents.value = false;
    }

    async function getIgnitionTrips() {}

    async function getDoorHistory() {
      if (!props.active) return;
      loadingLockHistory.value = true;
      const doorHistoryResult = await store.dispatch(
        "eyesafe/getLockCommandHistory",
        {
          vehicleId: vehicle.value.id,
          dateFrom: dayjs(doorHistoryFrom.value).format(),
          dateTo: dayjs().isSame(dayjs(doorHistoryTo.value), "day")
            ? dayjs().format()
            : dayjs(doorHistoryTo.value).format(),
        },
      );
      doorHistory.value = doorHistoryResult.validated;

      loadingLockHistory.value = false;
    }

    async function sendStartUnlockCommand() {
      EventBus.emit("confirm-dialog:show", {
        title: "Abilita avviamento",
        text: "Sei sicuro di voler abilitare l'avviamento del motore?",
        callback: async (confirm) => {
          if (confirm) {
            const result = await store.dispatch("eyesafe/startUnlock", {
              vehicleId: vehicle.value.id,
            });
            if (result.success) {
              EventBus.emit("notification:show", {
                type: "info",
                title: i18n.t("services.eyesafe.command_submitted"),
              });
            }
          }
        },
      });
    }

    async function sendStartLockCommand() {
      EventBus.emit("confirm-dialog:show", {
        title: "Disabilita avviamento locale",
        text: "Sei sicuro di voler disabilitare l'avviamento del motore?",
        callback: async (confirm) => {
          if (confirm) {
            const result = await store.dispatch("eyesafe/startLock", {
              vehicleId: vehicle.value.id,
            });
            if (result.success) {
              EventBus.emit("notification:show", {
                type: "info",
                title: i18n.t("services.eyesafe.command_submitted"),
              });
            }
          }
        },
      });
    }

    async function sendResetLockCommand() {
      EventBus.emit("confirm-dialog:show", {
        title: "Ripristino avviamento locale",
        text: "Sei sicuro di voler ripristinare l'avviamento del motore in locale?",
        callback: async (confirm) => {
          if (confirm) {
            const result = await store.dispatch("eyesafe/startReset", {
              vehicleId: vehicle.value.id,
            });
            if (result.success) {
              EventBus.emit("notification:show", {
                type: "info",
                title: i18n.t("services.eyesafe.command_submitted"),
              });
            }
          }
        },
      });
    }

    async function sendDoorUnlockCommand() {
      EventBus.emit("confirm-dialog:show", {
        title: "Sblocco serratura",
        text: "Sei sicuro di voler sbloccare la serratura?",
        callback: async (confirm) => {
          if (confirm) {
            const result = await store.dispatch("eyesafe/doorUnlock", {
              vehicleId: vehicle.value.id,
            });
            if (result.success) {
              EventBus.emit("notification:show", {
                type: "info",
                title: i18n.t("services.eyesafe.command_submitted"),
              });
            }
          }
        },
      });
    }

    return {
      i18n,
      dayjs,
      vehicle,
      ignitionStatus,
      ignitionData,
      ignitionHistory,
      ignitionEvents,
      lockStatus,
      doorData,
      doorHistory,
      requestIgnitionCommandsReport,
      requestIgnitionEventsReport,
      requestIgnitionTripsReport,
      requestLockCommandsReport,
      loadingIgnitionHistory,
      loadingIgnitionEvents,
      loadingLockHistory,
      ignitionCommandsDaterange,
      ignitionEventsDaterange,
      ignitionTripsDaterange,
      doorDaterange,
      sendStartUnlockCommand,
      sendStartLockCommand,
      sendResetLockCommand,
      sendDoorUnlockCommand,
      formatIgnitionStatus,
      formatIgnitionStatusClass,
      formatLockStatus,
      formatDoorStatus,
      ignitionDataAuthorizedDrivers,
      loadingAuthorizedDrivers,
      showAuthorizedDriversModal,
      getAuthorizedDrivers,
      openAuthorizedDriversModal,
      has_eyesafe_ignition,
      has_eyesafe_lock,
    };
  },
};
</script>
