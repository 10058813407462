import axios from "../libs/axios";
import { handleSuccess, handleError } from "./response-handler";
import dayjs from "dayjs";
const namespace = "services/eyesafe";
const api = {
  getIgnitionControlLastCommand: async function (id) {
    try {
      const response = await axios.get(
        `${namespace}/${id}/ignitioncontrol_last_command`,
      );
      return handleSuccess(
        { namespace, action: "getIgnitionControlLastCommand" },
        response,
      );
    } catch (error) {
      return handleError(
        { namespace, action: "getIgnitionControlLastCommand" },
        error.response,
      );
    }
  },

  getIgnitionControlCommandHistory: async function (id, from, to) {
    try {
      const response = await axios.get(
        `${namespace}/${id}/ignitioncontrol_command_history`,
        {
          params: {
            period_from: dayjs(from).format(),
            period_to: dayjs(to).format(),
          },
        },
      );
      return handleSuccess(
        { namespace, action: "getIgnitionControlCommandHistory" },
        response,
      );
    } catch (error) {
      return handleError(
        { namespace, action: "getIgnitionControlCommandHistory" },
        error.response,
      );
    }
  },

  getIgnitionControlEventHistory: async function (id, from, to) {
    try {
      const response = await axios.get(
        `${namespace}/${id}/ignitioncontrol_event_history`,
        {
          params: {
            period_from: dayjs(from).format(),
            period_to: dayjs(to).format(),
          },
        },
      );
      return handleSuccess(
        { namespace, action: "getIgnitionControlEventHistory" },
        response,
      );
    } catch (error) {
      return handleError(
        { namespace, action: "getIgnitionControlEventHistory" },
        error.response,
      );
    }
  },

  getLockControlLastCommand: async function (id) {
    try {
      const response = await axios.get(
        `${namespace}/${id}/lockcontrol_last_command`,
      );
      return handleSuccess(
        { namespace, action: "getLockControlLastCommand" },
        response,
      );
    } catch (error) {
      return handleError(
        { namespace, action: "getLockControlLastCommand" },
        error.response,
      );
    }
  },

  getLockControlCommandHistory: async function (id, from, to) {
    try {
      const response = await axios.get(
        `${namespace}/${id}/lockcontrol_command_history`,
        {
          params: {
            period_from: dayjs(from).format(),
            period_to: dayjs(to).format(),
          },
        },
      );
      return handleSuccess(
        { namespace, action: "getLockControlCommandHistory" },
        response,
      );
    } catch (error) {
      return handleError(
        { namespace, action: "getLockControlCommandHistory" },
        error.response,
      );
    }
  },

  getIgnitionControlStatus: async function (id) {
    try {
      const response = await axios.get(
        `${namespace}/${id}/ignitioncontrol_status`,
      );
      return handleSuccess(
        { namespace, action: "getIgnitionControlStatus" },
        response,
      );
    } catch (error) {
      return handleError(
        { namespace, action: "getIgnitionControlStatus" },
        error.response,
      );
    }
  },

  getLockControlStatus: async function (id) {
    try {
      const response = await axios.get(`${namespace}/${id}/lockcontrol_status`);
      return handleSuccess(
        { namespace, action: "getLockControlStatus" },
        response,
      );
    } catch (error) {
      return handleError(
        { namespace, action: "getLockControlStatus" },
        error.response,
      );
    }
  },

  getAuthorizedDrivers: async function (id) {
    try {
      const response = await axios.get(`${namespace}/${id}/authorized_drivers`);
      return handleSuccess(
        { namespace, action: "getAuthorizedDrivers" },
        response,
      );
    } catch (error) {
      return handleError(
        { namespace, action: "getAuthorizedDrivers" },
        error.response,
      );
    }
  },

  //IMMOBILIZER - LOCK AND UNLOCK VEHICLE STARTUP REMOTELY
  startUnlock: async function (id) {
    try {
      const response = await axios.put(`${namespace}/${id}/start_unlock`);
      return handleSuccess({ namespace, action: "startUnlock" }, response);
    } catch (error) {
      return handleError({ namespace, action: "startUnlock" }, error.response);
    }
  },
  startLock: async function (id) {
    try {
      const response = await axios.put(`${namespace}/${id}/start_lock`);
      return handleSuccess({ namespace, action: "startLock" }, response);
    } catch (error) {
      return handleError({ namespace, action: "startLock" }, error.response);
    }
  },
  startReset: async function (id) {
    try {
      const response = await axios.put(`${namespace}/${id}/start_reset`);
      return handleSuccess({ namespace, action: "startReset" }, response);
    } catch (error) {
      return handleError({ namespace, action: "startReset" }, error.response);
    }
  },
  //DOOR - UNLOCK DOOR REMOTELY
  doorUnlock: async function (id) {
    try {
      const response = await axios.put(`${namespace}/${id}/door_unlock`);
      return handleSuccess({ namespace, action: "doorUnlock" }, response);
    } catch (error) {
      return handleError({ namespace, action: "doorUnlock" }, error.response);
    }
  },
};
export { api as default };
