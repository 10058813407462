<template>
  <div class="flex flex-row h-full mx-auto">
    <div v-if="vehicle" class="h-12 flex self-start md:self-center -ml-7 mr-1">
      <button @click="$emit('close')">
        <ChevronRightIcon
          class="w-7 h-7 h-full bg-gray-300 mr-2 rounded shadow-md hover:bg-gray-400 shadow-xs"
        />
      </button>
    </div>

    <div v-if="vehicle" class="h-full flex flex-col flex-none w-full">
      <!-- heading -->
      <div
        class="flex flex-row w-full rounded-sm shadow-md p-1 bg-white border-b-2 border-gray-300"
      >
        <div
          class="flex flex-col p-2 px-3 justify-center border-r-2 border-gray-500"
        >
          <Tippy
            tag="div"
            class="tooltip w-full"
            :content="i18n.t(`vehicles.types.${vehicle.vehicle_type}`)"
            :alt="i18n.t(`vehicles.types.${vehicle.vehicle_type}`)"
          >
            <img
              :src="
                require(
                  `@/assets/images/vehicles/${vehicle.vehicle_type}-primary.svg`,
                )
              "
              class="mx-auto h-5 opacity-95 transform-gpu translate-y-1 mb-2"
            />
          </Tippy>
          <ConnectionBadge
            :status="vehicle.status"
            :popover="true"
            class="py-0"
          ></ConnectionBadge>
        </div>
        <div class="flex flex-col mr-auto gap-1 ml-2 my-auto">
          <span class="text-sm p-1 bg-white"
            ><CreditCardIcon /> &nbsp; <b>{{ vehicle.plate }}</b></span
          >
          <span
            v-if="vehicleHasFleet(vehicle.fleet_id)"
            class="text-sm p-1 bg-white"
            ><BookmarkIcon /> &nbsp; <b>{{ vehicleFleet(vehicle.fleet_id) }}</b>
          </span>
        </div>
      </div>

      <!-- services rows -->
      <div class="flex flex-wrap w-full flex-row border-b">
        <ul class="flex flex-wrap w-full gap-1">
          <template v-for="s in services" v-bind:key="s.key">
            <li
              v-if="s.active"
              :key="s.key"
              class="flex-auto w-1/5 reyesol-services-tab"
              :class="{
                selected: openedService === s.key,
              }"
              @click="s.active ? toggleTabs(s.key) : null"
            >
              <Tippy
                tag="div"
                class="tooltip w-full text-center pt-3"
                :content="s.name"
                :alt="s.name"
              >
                <NotificationBadge
                  :warning="s.warning.length"
                  :alarm="s.alarm.length"
                  :service="s.key"
                >
                  <img
                    :src="require(`@/assets/images/services/${s.key}.svg`)"
                    class="h-6"
                    :alt="`${s.name}`"
                  />
                </NotificationBadge>
              </Tippy>
            </li>
          </template>
        </ul>
      </div>

      <!--services card & Report Modal-->
      <div
        class="relative flex w-full flex-col bg-white mb-6 shadow-md overflow-auto rounded-sm"
      >
        <div class="p-4">
          <div class="tab-content tab-space">
            <!-- Services Containers-->
            <template v-for="s in services" v-bind:key="s.key">
              <div
                class="w-full"
                :class="{
                  hidden: openedService !== s.key,
                  block: openedService == s.key,
                }"
              >
                <div>
                  <AlertDetails
                    v-if="openedService == s.key"
                    :alarm="s.alarm"
                    :warning="s.warning"
                  />
                  <!--Services-->
                  <VehicleServiceSystem
                    v-if="s.key == 'system'"
                    :active="openedService == 'system'"
                    @report-request="onReportRequest"
                  />
                  <VehicleServiceEyegeo
                    v-if="s.key == 'eyegeo'"
                    :active="openedService == 'eyegeo'"
                    @report-request="onReportRequest"
                  />
                  <VehicleServiceEyesafe
                    v-if="s.key == 'eyesafe'"
                    :active="openedService == 'eyesafe'"
                    @report-request="onReportRequest"
                  />
                  <VehicleServiceEyecold
                    v-if="s.key == 'eyecold'"
                    :active="openedService == 'eyecold'"
                    @report-request="onReportRequest"
                  />
                  <VehicleServiceEyedoor
                    v-if="s.key == 'eyedoor'"
                    :active="openedService == 'eyedoor'"
                    @report-request="onReportRequest"
                  />
                  <VehicleServiceEyecrono
                    v-if="s.key == 'crono'"
                    :active="openedService == 'crono'"
                    @report-request="onReportRequest"
                  />
                  <VehicleServiceEyefuel
                    v-if="s.key == 'eyefuel'"
                    :active="openedService == 'eyefuel'"
                    @report-request="onReportRequest"
                  />
                  <VehicleServiceEyepower
                    v-if="s.key == 'eyepower'"
                    :active="openedService == 'eyepower'"
                    @report-request="onReportRequest"
                  />
                  <VehicleServiceEyeway
                    v-if="s.key == 'eyeway'"
                    :active="openedService == 'eyeway'"
                    @report-request="onReportRequest"
                  />
                  <VehicleServiceEyecar
                    v-if="s.key == 'eyecar'"
                    :active="openedService == 'eyecar'"
                    @report-request="onReportRequest"
                  />
                  <VehicleServiceEyeservice
                    v-if="s.key == 'eyeservice'"
                    :active="openedService == 'eyeservice'"
                    @report-request="onReportRequest"
                  />
                  <VehicleServiceCarbontax
                    v-if="s.key == 'carbontax'"
                    :active="openedService == 'carbontax'"
                  />
                  <VehicleServiceEyecontrol
                    v-if="s.key == 'eyecontrol'"
                    :active="openedService == 'eyecontrol'"
                    @report-request="onReportRequest"
                  />
                  <VehicleServiceEyeshare
                    v-if="s.key == 'eyeshare'"
                    :active="openedService == 'eyeshare'"
                  />
                  <VehicleServiceTandT
                    v-if="s.key == 'tandt'"
                    :active="openedService == 'tandt'"
                    @report-request="onReportRequest"
                  />
                  <VehicleServiceDirections
                    v-if="s.key == 'eyegeo.directions'"
                    :active="openedService == 'eyegeo.directions'"
                  />
                </div>
              </div>
            </template>

            <!--Report slider-->
            <ReportSlider
              :active="reportSelectorOpened"
              :save="i18n.t('global-map.report.save')"
              :cancel="i18n.t('global-map.report.cancel')"
              :selected="report_selected"
              :enabled="report_enabled"
              :type="report_type"
              @save="onReportSave"
              @close="onReportClose"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, watch } from "vue";
import { useI18n } from "vue3-i18n";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import dayjs from "dayjs";
import EventBus from "@/libs/event-bus";
import { useDaterange } from "@/composables/daterange";
import NotificationBadge from "@/components/alerts/NotificationBadge.vue";
import AlertDetails from "@/components/alerts/AlertDetails.vue";
import VehicleServiceSystem from "@/views/vehicle/System.vue";
import VehicleServiceEyegeo from "@/views/vehicle/Eyegeo.vue";
import VehicleServiceEyesafe from "@/views/vehicle/Eyesafe.vue";
import VehicleServiceEyecar from "@/views/vehicle/Eyecar.vue";
import VehicleServiceEyecold from "@/views/vehicle/Eyecold.vue";
import VehicleServiceEyedoor from "@/views/vehicle/Eyedoor.vue";
import VehicleServiceEyefuel from "@/views/vehicle/Eyefuel.vue";
import VehicleServiceEyepower from "@/views/vehicle/Eyepower.vue";
import VehicleServiceEyeway from "@/views/vehicle/Eyeway.vue";
import VehicleServiceEyecrono from "@/views/vehicle/Eyecrono.vue";
import VehicleServiceEyeservice from "@/views/vehicle/Eyeservice.vue";
import VehicleServiceEyeshare from "@/views/vehicle/Eyeshare.vue";
import VehicleServiceCarbontax from "@/views/vehicle/Carbontax.vue";
import VehicleServiceEyecontrol from "@/views/vehicle/Eyecontrol.vue";
import VehicleServiceTandT from "@/views/vehicle/TandT.vue";
import VehicleServiceDirections from "@/views/vehicle/Directions.vue";

import ReportSlider from "@/components/report-slide/ReportSlider.vue";
import ConnectionBadge from "@/components/connection-status/ConnectionBadge.vue";

import { BookmarkIcon } from "@zhuowenli/vue-feather-icons";

export default {
  name: "SlateTabs",
  components: {
    VehicleServiceSystem,
    VehicleServiceEyegeo,
    VehicleServiceEyesafe,
    VehicleServiceEyepower,
    VehicleServiceEyecontrol,
    VehicleServiceEyecold,
    VehicleServiceEyedoor,
    VehicleServiceEyefuel,
    VehicleServiceEyecrono,
    VehicleServiceEyeway,
    VehicleServiceEyeservice,
    VehicleServiceEyeshare,
    VehicleServiceCarbontax,
    VehicleServiceTandT,
    VehicleServiceEyecar,
    VehicleServiceDirections,
    ReportSlider,
    BookmarkIcon,
    NotificationBadge,
    ConnectionBadge,
    AlertDetails,
  },
  props: {
    service: {
      type: [String, null],
      default: null,
    },
    vehicle: {
      type: [Object, null],
      default: null,
    },
  },
  emits: ["close"],
  setup(props) {
    const i18n = useI18n();
    const store = useStore();
    const router = useRouter();
    const user = store.getters["auth/user"];

    //services
    const mainServices = store.getters["main/services"];
    const services = ref([]);
    const agreements = store.getters["agreements/collection"];
    const openedService = ref("system");

    //reporting
    const report_selected = ref([]);
    const report_enabled = ref([]);
    const report_type = ref("custom");
    const reportSelectorOpened = ref(false);

    const activeVehicle = ref(null);

    const { from: historyFrom, to: historyTo } = useDaterange({
      onChange: onDaterangeChange,
    });

    //Load Services
    function loadServices(vehicle) {
      vehicle.active_agreements.map((agreement) => {
        agreement.code = agreements.find(
          (a) => a.id == agreement.agreement_id,
        ).code;
        return agreement;
      });

      console.log("vehicle.alerts", vehicle.alerts);
      const active_alerts = vehicle.alerts
        .map((active_alert) => {
          const alerts = store.getters["alerts/collection"];
          const alert = alerts.find((a) => a.id == active_alert.alert_id);
          active_alert.description = alert.description;
          active_alert.severity = alert.severity;
          active_alert.agreement_id = alert.agreement_id;
          active_alert.service = agreements.find(
            (a) => a.id == alert.agreement_id,
          ).code;
          return active_alert;
        })
        .filter((a) => a.severity > 0);
      services.value = mainServices.map((service) => {
        const is_active =
          !!vehicle.active_agreements.find(
            (a) => a.customer_id == user.customer_id && a.code == service.key,
          ) || service.key == "system";
        return {
          visible: !service.key.startsWith("type_"),
          warning:
            is_active && active_alerts
              ? active_alerts.filter(
                  (a) =>
                    a.service.split(".")[0] == service.key && a.severity == 1,
                )
              : [],
          alarm:
            is_active && active_alerts
              ? active_alerts.filter(
                  (a) =>
                    a.service.split(".")[0] == service.key && a.severity == 2,
                )
              : [],
          active: is_active,
          ...service,
        };
      });
    }

    //UI UX
    function closePanel() {
      router.back();
    }

    function toggleTabs(tab) {
      openedService.value = tab;
    }

    EventBus.on("vehicle:updates:vehicle", updateVehicle);
    function updateVehicle(vehicle) {
      console.log("updateVehicle(vehicle)", vehicle);
      if (vehicle.value == null) return;

      if (vehicle) store.dispatch("globalMap/getVehicle", vehicle.id);
      return;
    }

    function onReportRequest(service, type, enabled, selected) {
      console.log("onReportRequest", service, type, enabled, selected);
      report_selected.value.splice(0, report_selected.value.length);
      if (service) report_selected.value.push(...service);

      if (enabled) {
        report_enabled.value.splice(0, report_enabled.value.length);
        report_enabled.value.push(...enabled);
      }
      if (enabled) {
        report_enabled.value.splice(0, report_enabled.value.length);
        report_enabled.value.push(...enabled);
      }
      report_type.value = type;
      reportSelectorOpened.value = true;
    }

    function onReportClose() {
      reportSelectorOpened.value = false;
    }

    function onReportSave(services, from, to, type) {
      console.log("onReportSave", services, from, to, type);
      store.dispatch("globalMap/requestReport", {
        vehicleId: props.vehicle.id,
        services: services.join(),
        from: dayjs(from).format(),
        to: dayjs(to).format(),
        type: type,
      });

      EventBus.emit("notification:show", {
        type: "success",
        title: i18n.t("reports.notify.submitted"),
      });
    }

    function vehicleHasFleet(fleet_id) {
      const fleets = store.getters["fleets/collection"];
      const obj = fleets.find((o) => o.id == fleet_id);
      if (obj) return true;
      else return false;
    }

    function vehicleFleet(fleet_id) {
      const fleets = store.getters["fleets/collection"];
      const obj = fleets.find((o) => o.id == fleet_id);
      if (obj) return obj.code;
      else return "N/A";
    }

    //helpers & interactive
    async function onDaterangeChange() {
      console.log("Report onDaterangeChange");
      if (!props.active) return;
    }

    function capitalizeFirst(str) {
      return str
        .split(" ")
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(" ");
    }

    //INIT
    watch(
      () => props.vehicle,
      () => {
        if (props.vehicle && props.vehicle.id != activeVehicle.value) {
          activeVehicle.value = props.vehicle.id;

          loadServices(props.vehicle);
          reportSelectorOpened.value = false;
          toggleTabs(props.service ?? "system");

          const has_eyegeo = props.vehicle.active_agreements.find(
            (o) => o.agreement_id == 2,
          );
          if (has_eyegeo) {
            setTimeout(() => {
              EventBus.emit("service:history", {
                vehicle_id: props.vehicle.id,
                from: historyFrom.value,
                to: historyTo.value,
              });
              console.log(
                'EventBus.emit("service:history", props.vehicle.id, historyFrom, historyTo)',
                props.vehicle.id,
                historyFrom.value,
                historyTo.value,
              );
            }, 400);
          }
        }
      },
    );

    return {
      reportSelectorOpened,
      dayjs,
      i18n,
      services,
      openedService,
      toggleTabs,
      onReportRequest,
      closePanel,
      report_selected,
      report_enabled,
      report_type,
      onReportSave,
      onReportClose,
      vehicleFleet,
      vehicleHasFleet,
      capitalizeFirst,
    };
  },
};
</script>

<style></style>
