import { eyesafe as api } from "@/api";

const state = () => {
  return {
    ignitionLastCommand: [],
    ignitionCommandHistory: [],
    lockLastCommand: [],
    lockCommandHistory: [],
  };
};

// getters
const getters = {
  ignitionLastCommand: (state) => (vehicleId) => {
    return state.ignitionLastCommand.find((d) => d.vehicle_id === vehicleId);
  },
  ignitionCommandHistory: (state) => (vehicleId) => {
    return state.ignitionCommandHistory.find((h) => h.vehicle_id === vehicleId);
  },
  lockLastCommand: (state) => (vehicleId) => {
    return state.lockLastCommand.find((d) => d.vehicle_id === vehicleId);
  },
  lockCommandHistory: (state) => (vehicleId) => {
    return state.lockCommandHistory.find((h) => h.vehicle_id === vehicleId);
  },
};

// actions
const actions = {
  async getIgnitionLastCommand({ dispatch }, vehicleId) {
    // const cachedData = getters.ignitionLastCommand(vehicleId);
    // if (cachedData) return { validated: cachedData };
    return await dispatch("fetchIgnitionLastCommand", vehicleId);
  },

  async fetchIgnitionLastCommand({ commit, getters }, vehicleId) {
    const result = await api.getIgnitionControlLastCommand(vehicleId);
    if (result.success) {
      commit("REMOVE_IGNITION_LAST_COMMAND", vehicleId);
      commit("PUSH_IGNITION_LAST_COMMAND", {
        vehicle_id: vehicleId,
        ...result.data,
      });
    }
    result.validated = getters.ignitionLastCommand(vehicleId);
    return result;
  },

  async getIgnitionCommandHistory({ dispatch }, vehicleId) {
    // const cachedData = getters.ignitionCommandHistory(vehicleId);
    // if (cachedData) return { validated: cachedData };
    return await dispatch("fetchIgnitionCommandHistory", vehicleId);
  },

  async fetchIgnitionCommandHistory(
    { commit, getters },
    { vehicleId, dateFrom, dateTo },
  ) {
    const result = await api.getIgnitionControlCommandHistory(
      vehicleId,
      dateFrom,
      dateTo,
    );
    result.validated = [];
    if (result.success) {
      commit("REMOVE_IGNITION_COMMAND_HISTORY", vehicleId);
      commit("PUSH_IGNITION_COMMAND_HISTORY", {
        vehicle_id: vehicleId,
        list: result.data,
      });
      result.validated = getters.ignitionCommandHistory(vehicleId);
    }
    return result;
  },

  async getIgnitionEventHistory({ dispatch }, vehicleId) {
    return await dispatch("fetchIgnitionEventHistory", vehicleId);
  },

  async fetchIgnitionEventHistory(_, { vehicleId, dateFrom, dateTo }) {
    const result = await api.getIgnitionControlEventHistory(
      vehicleId,
      dateFrom,
      dateTo,
    );
    result.validated = [];
    if (result.success) {
      result.validated = result.data;
    }
    return result;
  },

  // LOCK actions
  async getLockLastCommand({ dispatch }, vehicleId) {
    return await dispatch("fetchLockLastCommand", vehicleId);
  },

  async fetchLockLastCommand({ commit, getters }, vehicleId) {
    const result = await api.getLockControlLastCommand(vehicleId);
    if (result.success) {
      commit("REMOVE_LOCK_LAST_COMMAND", vehicleId);
      commit("PUSH_LOCK_LAST_COMMAND", {
        vehicle_id: vehicleId,
        ...result.data,
      });
    }
    result.validated = getters.lockLastCommand(vehicleId);
    return result;
  },

  async getLockCommandHistory({ dispatch }, vehicleId) {
    return await dispatch("fetchLockCommandHistory", vehicleId);
  },

  async getIgnitionStatus(_, vehicleId) {
    const result = await api.getIgnitionControlStatus(vehicleId);
    result.validated = [];
    if (result.success) {
      result.validated = result.data;
    }
    return result;
  },

  async getLockStatus(_, vehicleId) {
    const result = await api.getLockControlStatus(vehicleId);
    result.validated = [];
    if (result.success) {
      result.validated = result.data;
    }
    return result;
  },

  async getAuthorizedDrivers(_, vehicleId) {
    const result = await api.getAuthorizedDrivers(vehicleId);
    result.validated = [];
    if (result.success) {
      result.validated = result.data.drivers;
    }
    return result;
  },

  async fetchLockCommandHistory(
    { commit, getters },
    { vehicleId, dateFrom, dateTo },
  ) {
    const result = await api.getLockControlCommandHistory(
      vehicleId,
      dateFrom,
      dateTo,
    );
    result.validated = [];
    if (result.success) {
      commit("REMOVE_LOCK_COMMAND_HISTORY", vehicleId);
      commit("PUSH_LOCK_COMMAND_HISTORY", {
        vehicle_id: vehicleId,
        list: result.data,
      });
      result.validated = getters.lockCommandHistory(vehicleId);
    }
    return result;
  },

  // IMMOBILIZER - LOCK AND UNLOCK VEHICLE STARTUP REMOTELY
  async startUnlock(_, { vehicleId }) {
    return await api.startUnlock(vehicleId);
  },
  async startLock(_, { vehicleId }) {
    return await api.startLock(vehicleId);
  },
  async startReset(_, { vehicleId }) {
    return await api.startReset(vehicleId);
  },

  // LOCK - UNLOCK DOOR REMOTELY
  async doorUnlock(_, { vehicleId }) {
    return await api.doorUnlock(vehicleId);
  },
};

// mutations
const mutations = {
  PUSH_IGNITION_LAST_COMMAND(state, ignitionCommand) {
    state.ignitionLastCommand.push(ignitionCommand);
  },
  REMOVE_IGNITION_LAST_COMMAND(state, vehicleId) {
    const i = state.ignitionLastCommand.findIndex(
      (d) => d.vehicle_id == vehicleId,
    );
    if (i != -1) state.ignitionLastCommand.splice(i, 1);
  },
  PUSH_IGNITION_COMMAND_HISTORY(state, ignitionCommandHistory) {
    state.ignitionCommandHistory.push(ignitionCommandHistory);
  },
  REMOVE_IGNITION_COMMAND_HISTORY(state, vehicleId) {
    const i = state.ignitionCommandHistory.findIndex(
      (h) => h.vehicle_id == vehicleId,
    );
    if (i != -1) state.ignitionCommandHistory.splice(i, 1);
  },
  PUSH_LOCK_LAST_COMMAND(state, lockCommand) {
    state.lockLastCommand.push(lockCommand);
  },
  REMOVE_LOCK_LAST_COMMAND(state, vehicleId) {
    const i = state.lockLastCommand.findIndex((d) => d.vehicle_id == vehicleId);
    if (i != -1) state.lockLastCommand.splice(i, 1);
  },
  PUSH_LOCK_COMMAND_HISTORY(state, lockCommandHistory) {
    state.lockCommandHistory.push(lockCommandHistory);
  },
  REMOVE_LOCK_COMMAND_HISTORY(state, vehicleId) {
    const i = state.lockCommandHistory.findIndex(
      (h) => h.vehicle_id == vehicleId,
    );
    if (i != -1) state.lockCommandHistory.splice(i, 1);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
