<template>
  <!--Report Modal-->
  <VueFinalModal
    v-model="is_shown"
    class="flex justify-center items-center"
    content-class="flex flex-col bg-white rounded-lg space-y-2 max-w-2xl w-full max-h-screen"
    @closed="onClose"
  >
    <!-- Modal content-->
    <div class="flex-grow m-4 overflow-y-scroll">
      <!-- Daterange + title-->
      <div class="flex flex-col">
        <div class="flex text-lg mb-4 self-center">
          {{ i18n.t("general.request-report") }}
        </div>
        <div class="flex flex-col bg-white">
          <span class="text-md">
            {{ i18n.t("general.report-slide-period") }}
          </span>
          <div class="flex relative">
            <Eyepicker v-model="daterange" :max-days="31" />
          </div>
        </div>
      </div>

      <!-- content table-->
      <div class="flex flex-col">
        <div class="flex text-md mb-2 mt-2">
          {{ i18n.t("navigation.services") }}:
        </div>
      </div>
      <div class="flex flex-col justify-center">
        <template v-for="service in services" :key="service.key">
          <div
            v-if="service.show"
            class="flex border border-gray-300 p-2 rounded-md mb-2"
          >
            <div class="flex custom-control custom-switch">
              <template v-if="!service.show_children">
                <input
                  :id="`toggle-${service.key}`"
                  v-model="service.selected"
                  type="checkbox"
                  class="form-check-switch"
                  :key="service.key"
                />

                <label
                  class="custom-control-label ml-2 self-center"
                  :for="'toggle-' + service.key"
                >
                  {{ i18n.t(`services.${service.key}.name`) }}
                </label>
              </template>
              <template v-else>
                <h3 class="m-4">
                  {{ i18n.t(`services.${service.key}.name`) }}
                </h3>
                <div class="flex flex-wrap">
                  <div
                    v-for="parameter in service.parameters"
                    :key="parameter.key"
                    class="pl-2 mb-2 flex basis-1/3"
                  >
                    <input
                      :id="`toggle-${service.key}-${parameter.key}`"
                      v-model="parameter.selected"
                      type="checkbox"
                      class="form-check-switch self-center"
                    />
                    <label
                      class="custom-control-label ml-2 self-center"
                      :for="'toggle-' + parameter.key"
                    >
                      {{
                        i18n.t(
                          `reports.services.${parameter.service}.${parameter.key}`,
                        )
                      }}
                    </label>
                  </div>
                </div>
              </template>
            </div>
          </div>
        </template>
      </div>
    </div>

    <!-- Modal buttons-->
    <div class="flex flex-row gap-4 justify-center pb-4">
      <button
        class="modal-default-button btn btn-secondary hover:bg-blue-500 hover:text-white"
        @click="onClose"
      >
        {{ i18n.t("general.cancel") }}
      </button>
      <button
        class="modal-default-button btn btn-primary"
        :disabled="
          false &&
          !services.some(
            (e) =>
              e.selected == true &&
              e.parameters.some((e) => e.selected == true),
          )
        "
        @click="onSave"
      >
        {{ i18n.t("reports.generate-report") }}
      </button>
    </div>
  </VueFinalModal>
</template>

<script>
import { watch, ref } from "vue";
import { useI18n } from "vue3-i18n";
import { useDaterange } from "@/composables/daterange";
import { VueFinalModal } from "vue-final-modal";
import dayjs from "dayjs";

export default {
  components: {
    VueFinalModal,
  },
  name: "ReportSlider",
  props: {
    active: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "Report",
    },
    type: {
      type: String,
      default: "custom",
    },
    save: {
      type: String,
      default: "Save",
    },
    cancel: {
      type: String,
      default: "Close",
    },
    enabled: {
      type: [Array],
      default: () => [],
    },
    selected: {
      type: [Array],
      default: () => [],
    },
  },
  setup(props, { emit }) {
    const all_services = [
      "eyegeo.latitude",
      "eyegeo.longitude",
      "eyegeo.speed",
      "eyegeo.km",
      "eyegeo.address",
      "eyecold.T1",
      "eyecold.T2",
      "eyecold.T3",
      "eyecold.T4",
      "eyecold_fridge.overall_status",
      "eyecold_fridge.engine_status",
      "eyecold_fridge.operating_mode",
      "eyecold_fridge.operating_mode_status",
      "eyecold_fridge.setpoint_t1",
      "eyecold_fridge.operation_status_c1",
      "eyecold_fridge.operation_mode_c1",
      "eyecold_fridge.setpoint_t2",
      "eyecold_fridge.operation_status_c2",
      "eyecold_fridge.operation_mode_c2",
      "eyecold_fridge.setpoint_t3",
      "eyecold_fridge.operation_status_c3",
      "eyecold_fridge.operation_mode_c3",
      "eyecold_fridge.alarm_status",
      "eyecold_fridge.hour_meter_electric",
      "eyecold_fridge.hour_meter_fuel",
      "eyecold_fridge.current_sensor",
      "eyecold_fridge.odl_num",
      "eyecold_fridge.odl_t1",
      "eyedoor.door_1",
      "eyedoor.door_2",
      "eyedoor.door_3",
      "eyedoor.door_4",
      "eyedoor.siren",
      "eyefuel.tank_usage",
      "eyefuel.cap_status",
      "eyefuel.alarm_status",
      "eyeway.odometer",
      "eyeway.speed",
      "eyeway.breaks_status",
      "eyeway.tyres_pressure_status",
      "eyeway.load",
      "eyecontrol.user",
      "eyecontrol.command",
      "eyecontrol.command_data",
      "eyecontrol.status",

      //EYESAFE IGNITION
      "eyesafe_ignition.user",
      "eyesafe_ignition.action",
      "eyesafe_ignition.command",
      "eyesafe_ignition.command_data",
      "eyesafe_ignition.status",
      "eyesafe_ignition.user_ip",
      //EYESAFE LOCK
      "eyesafe_lock.user",
      "eyesafe_lock.action",
      "eyesafe_lock.command",
      "eyesafe_lock.command_data",
      "eyesafe_lock.status",
      "eyesafe_lock.user_ip",

      "tandt.plate",
      "tandt.locked",
      "tandt.authorized",
      "drivers.report",
      "tco.report",
    ];
    const is_shown = ref(false);
    const actives = ref([]);
    const services = ref([]);
    const daterange = ref({
      from: dayjs().startOf("day").toDate(),
      to: dayjs().toDate(),
    });

    function onClose() {
      emit("close", actives.value);
      clear();
    }

    function onSave() {
      is_shown.value = false;
      let active_services = [];
      if (["custom", "temp"].includes(props.type)) {
        for (let key = 0; key < services.value.length; key++) {
          active_services = active_services.concat(
            services.value[key].parameters
              .filter((p) => p.selected)
              .map((p) => p.service + "." + p.key),
          );
        }
      } else {
        for (let key = 0; key < services.value.length; key++) {
          if (services.value[key].selected) {
            active_services = active_services.concat(
              services.value[key].parameters
                .filter((p) => p.selected)
                .map((p) => p.service + "." + p.key),
            );
          }
        }
      }
      emit(
        "save",
        active_services,
        daterange.value[0], // FROM
        daterange.value[1], // TO
        props.type,
      );
    }

    // get onchange event from Daterange
    async function onDaterangeChange() {
      console.log("Report onDaterangeChange");
      if (!props.active) return;
    }

    function clear() {}

    function init() {
      services.value = [];
      let enabled_services;
      if (props.enabled.length == 0) enabled_services = all_services;
      else enabled_services = props.enabled;

      for (let key = 0; key < all_services.length; key++) {
        const [s, p] = all_services[key].split(".");
        let fSI = services.value.findIndex((x) => s.startsWith(x.key));
        if (fSI == -1) {
          services.value.push({
            show: enabled_services.find((o) => o.split(".")[0] == s)
              ? true
              : false,
            selected: props.selected.find((o) => o.split(".")[0] == s)
              ? true
              : false,
            key: s,
            show_children: ["custom", "temp"].includes(props.type),
            parameters: [],
          });
          fSI = services.value.length - 1;
        }
        if (services.value[fSI].show_children) {
          if (
            enabled_services.find((o) => {
              const sp = o.split(".");
              return sp[0] == s && sp[1] == p;
            })
          ) {
            services.value[fSI].parameters.push({
              key: p,
              service: s,
              selected: props.selected.find((o) => {
                const sp = o.split(".");
                return sp[0] == s && sp[1] == p;
              })
                ? true
                : false,
            });
          }
        } else {
          if (
            enabled_services.find((o) => {
              const sp = o.split(".");
              return sp[0] == s && sp[1] == p;
            })
          ) {
            services.value[fSI].parameters.push({
              key: p,
              service: s,
              selected: true,
            });
          }
        }
      }

      const dt_range = useDaterange({ onDaterangeChange });

      daterange.value = [dt_range.from.value, dt_range.to.value];

      is_shown.value = props.active;
    }

    watch(() => props, init, { deep: true });

    init();

    return {
      i18n: useI18n(),
      onClose,
      onSave,
      actives,
      daterange,
      services,
      is_shown,
    };
  },
};
</script>
