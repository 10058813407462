import { createStore } from "vuex";
import main from "./main";
import notifications from "./notifications";
import reports from "./reports";
import customers from "./customers";
import users from "./users";
import agreements from "./agreements";
import alerts from "./alerts";
import drivers from "./drivers";
import driver_activities from "./driver_activities";
import types from "./types";
import type_activities from "./type_activities";
import topMenu from "./top-menu";
import sideMenu from "./side-menu";
import auth from "./auth";
import vehicles from "./vehicles";
import vehicle_rents from "./vehicle_rents";
import hubs from "./hubs";
import pois from "./pois";
import dashboard from "./dashboard";
import fleets from "./fleets";
import eyegeo from "./eyegeo";
import eyecold from "./eyecold";
import eyecrono from "./eyecrono";
import eyedoor from "./eyedoor";
import eyesafe from "./eyesafe";
import tandt from "./tandt";
import eyecontrol from "./eyecontrol";
import eyefuel from "./eyefuel";
import eyepower from "./eyepower";
import carbontax from "./carbontax";
import eyeway from "./eyeway";
import eyeservice from "./eyeservice";
import globalMap from "./global-map";
import globalSearch from "./global-search";
import trips from "./trips";

const store = createStore({
  modules: {
    main,
    reports,
    notifications,
    customers,
    users,
    carbontax,
    agreements,
    alerts,
    dashboard,
    topMenu,
    sideMenu,
    vehicles,
    vehicle_rents,
    hubs,
    pois,
    drivers,
    driver_activities,
    types,
    type_activities,
    fleets,
    auth,
    eyegeo,
    eyecrono,
    eyecold,
    eyecontrol,
    eyedoor,
    tandt,
    eyefuel,
    eyepower,
    eyeway,
    eyesafe,
    eyeservice,
    globalMap,
    globalSearch,
    trips,
  },
});

export function useStore() {
  return store;
}

export default store;
